import React, { Component, useEffect } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { SRLWrapper } from "simple-react-lightbox"; /* import lightbox */
import Siema from "siema"; /* import carusel */

/* Import nagłównka z menu */
import Header from "./Header";

/* Import sekcji kontakt */
import Contact from "./Contact";

/* Import zdjęć na stronę główną */
import video from "../images/video.mp4";
import video_big from ".././images/video_big.mp4";
import video_small from ".././images/video_small.mp4";
import onas from ".././images/1.jpg";
import psdm from ".././images/logo_PSDM.png";
import evo from ".././images/logo_EVO.png";
import invest from ".././images/3.jpg";
import ang from ".././images/logo_ang.png";
import goldimg from ".././images/logo_PMTH.png"
import license from ".././images/licencja_KNF.pdf";
import poster from ".././images/plakat.jpg";
import posterm from ".././images/plakat_m.jpg";

/* Import zdjęć firm */

import w1 from ".././images/Cooperation/logo_wspolpracownik_2.jpg";
//import w2 from ".././images/Cooperation/logo_wspolpracownik_3.jpg";
import w3 from ".././images/Cooperation/logo_wspolpracownik_4.jpg";
import w4 from ".././images/Cooperation/logo_wspolpracownik_5.jpg";
import w5 from ".././images/Cooperation/logo_wspolpracownik_6.jpg";
import w6 from ".././images/Cooperation/logo_wspolpracownik_7.jpg";
import w7 from ".././images/Cooperation/logo_wspolpracownik_8.jpg";
import w8 from ".././images/Cooperation/logo_wspolpracownik_9.jpg";
//import w9 from ".././images/Cooperation/logo_wspolpracownik_10.jpg";
import w10 from ".././images/Cooperation/logo_wspolpracownik_1.jpg";


/* Import zdjęć zespołu */
import z1 from ".././images/Team/Z1.jpg";
import z2 from ".././images/Team/Z2.jpg";
import z3 from ".././images/Team/Z3.jpg";
import z4 from ".././images/Team/Z4.jpg";
import z5 from ".././images/Team/Z5.jpg";
import z6 from ".././images/Team/Z6.jpg";
import z7 from ".././images/Team/Z7.jpg";
import z8 from ".././images/Team/Z8.jpg";
import z9 from ".././images/Team/Z9.jpg";
import z10 from ".././images/Team/Z10.jpg";

/* Definicja ustawień wyświetlania lightbox'a */
const img_options = {
  buttons: {
    backgroundColor: "rgba(30,30,36,0.8)",
    iconColor: "rgba(255, 255, 255, 0.8)",
    iconPadding: "5px",
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: false,
    showPrevButton: false,
    size: "40px"
  },
  caption: {
    showCaption: false
  },
  thumbnails: {
    showThumbnails: false
  },
  settings: {
    overlayColor: "rgba(0, 0, 0, 0.9)",
    transitionTimingFunction: "ease-in-out",
    disablePanzoom: true,
    disableWheelControls: true,
    hideControlsAfter: false
  }
};

/* Przejście na górę strony po jej odświeżeniu */
class ScrollToTopOnMount extends Component {
  componentDidMount() {
    /* Resetowanie zapamiętanej pozycji przez niektóre przeglądarki (Chrome) */
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    return null
  }
}

/* Generowanie strony głównej */
class Home extends Component {
  render() {
    return (
      <Container fluid>
        <ScrollToTopOnMount />
        <Header /> {/* Wyświetlenie nagłówka */}
        <Slider /> {/* Wyświetlenie slidera */}
        <div className="background-pattern">
          <About />  {/* Wyświetlenie sekcji "O Nas" */}
          <Investments /> {/* Wyświetlenie sekcji "Inwestycje" */}
        </div>
        <div className="background-pattern">
         {/* <Credits /> */} {/* Wyświetlenie sekcji "Kredyty" */}
          <Gold /> {/* Wyświetlenie sekcji "Złoto" */}
          <Team /> {/* Wyświetlenie sekcji "Nasz Zespół" */}
        </div>
        <div className="background-pattern-bottom">
          <Contact /> {/* Wyświetlenie sekcji "Kontakt" */}
        </div>
      </Container>
    );
  }
}

/* Generowanie slidera */
class Slider extends Component {
  render() {
    return (
      <section className="slider scrollspy" id="home">
        <Container fluid>
          <video className="videoTag big" autoPlay loop muted playsInline poster={poster}> {/* Wyświetlanie video dla dużych ekranów. Automatyczne włączenie */}
            <source src={video_big} type="video/mp4" />
          </video>
          <video className="videoTag small" autoPlay loop muted playsInline poster={poster}>{/* Wyświetlanie video dla małych ekranów. Automatyczne włączenie */}
            <source src={video} type="video/mp4" />
          </video>
          <video className="videoTag smallx" autoPlay loop muted playsInline poster={posterm}>{/* Wyświetlanie video dla małych ekranów. Automatyczne włączenie */}
            <source src={video_small} type="video/mp4" />
          </video>
          <div id="page-top"></div>
        </Container>
      </section>
    );
  }
}

/* Generowanie sekcji "O Nas" */
class About extends Component {
  render() {
    return (
      <section className="gold-separator scrollspy" id="about">
        <Container className="moving-show enable">

          {/* Nagłówek sekcji */}
          <Row className="section-name">
            <Col lg={12} md={12} sm={12} xs={12}>
              <h1><Trans>O nas</Trans></h1>
            </Col>
          </Row>

          {/* Treść sekcji */}
          <Row className="animation flex-center">
            <Col lg={6} md={12} sm={12} xs={12}>
              <p>
                <Trans i18nKey="About-description">BusinessWise to niezależna firma działająca na polskim rynku
                finansowym powołana w&nbsp;celu ochrony i&nbsp;pomnażania majątku
                zamożnych klientów. Dzięki wieloletnim doświadczeniom zebranym w&nbsp;departamentach Private Banking wiemy jak skutecznie poruszać się
                po&nbsp;świecie finansów osobistych.</Trans>
              </p>
              <ul>
                <li>
                  <Trans i18nKey="About-list-1">Podchodzimy do naszych klientów indywidualnie wsłuchując się w&nbsp;ich potrzeby. Zależy nam na długoterminowych relacjach i&nbsp;budowaniu wzajemnego zaufania.</Trans>
                </li>
                <li>
                  <Trans i18nKey="About-list-2">Nasz zespół jest obecny w&nbsp;kilku największych miastach w&nbsp;Polsce.</Trans>
                </li>
              </ul>
            </Col>
            <Col lg={6} md={12} sm={12} xs={12}>
              <SRLWrapper options={img_options}> {/* Włączenie lightbox'a dla zdjęcia "o nas" */}
                <img src={onas} className="onas" alt="O nas" />
              </SRLWrapper>
            </Col>
          </Row>

          {/* Złoty separator wyświetlany tylko na użądzeniach mobilnych */}
          <Row className="padding-top-sep show-on-mobile">
            <div className="little-gold-separator"></div>
          </Row>

        </Container>
      </section>
    );
  }
}

/* Generowanie sekcji "Investycje" */
class Investments extends Component {
  render() {
    return (
      <section className="investments scrollspy" id="investments">
        <Container className="moving-show">

          {/* Nagłówek sekcji */}
          <Row className="section-name">
            <Col lg={12} md={12} sm={12} xs={12}>
              <h1><Trans>Agent Firmy Inwestycyjnej</Trans></h1>
            </Col>
          </Row>

          {/* Treść sekcji */}
          <Row className="animation">
            <Col lg={3} md={3} sm={6} xs={12}>
              <a href="https://www.evodm.pl/"><img src={evo} className="psdm" alt="Logo EVO" /></a>
            </Col>
            <Col lg={9} md={9} sm={12} xs={12}>
              <p>
                <Trans i18nKey="Investments-description-1">BusinessWise jest licencjonowanym podmiotem rynku kapitałowego
                wpisanym na&nbsp;podstawie decyzji administracyjnej Urzędu Komisji
                Nadzoru finansowego z&nbsp;dnia 14.02.2020 roku do&nbsp;rejestru Agentów
                Firm Inwestycyjnych (DIF-WL.4011.162.2018.495.118).</Trans>
              </p>
              <p>
                <Trans i18nKey="Investments-description-2">Czynności agencyjne świadczymy na&nbsp;rzecz <a href="https://www.evodm.pl/">Evo Dom
                Maklerski S.A.</a></Trans>
              </p>
              <div className="little-gold-separator hide-on-mobile"></div>
            </Col>
          </Row>
          <Row className="animation">
            <Col lg={3} md={0} sm={0} xs={0}></Col>
            <Col lg={5} md={6} sm={12} xs={12}>
              <p>
                <Trans i18nKey="Investments-description-3">Dzięki tej współpracy posiadamy szeroki wachlarz
                wyselekcjonowanych rozwiązań inwestycyjnych, a&nbsp;naszymi
                partnerami są najlepsze Towarzystwa Funduszy inwestycyjnych w&nbsp;Polsce.</Trans>
              </p>
              <p className="p-small-text">
                <Trans i18nKey="Investments-description-4">Działalność spółki jest nadzorowana oraz kontrolowana przez Dom
                Maklerski oraz KNF.</Trans> <br />
                <a href={license} target="_blank" rel="noopener noreferrer"><Trans>Pobierz podgląd licencji KNF.</Trans></a>
              </p>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <img src={invest} className="invest-img" alt="Inwestycje" />
            </Col>
          </Row>

        </Container>
        <Container className="animation">

          {/* Nagłówek sekcji */}
          <Row className="section-name">
            <Col lg={12} md={12} sm={12} xs={12} className="white-h1">
              <h1><Trans>Współpracujemy z</Trans>:</h1>
            </Col>
          </Row>

        </Container>
        <div className="Cooperation-carousel gold-separator ">
          <Container className="top-bottom-padding moving-show">

            {/* Karuzela i wszystkie loga */}
            <div className="carousel">
              <img src={w1} alt="Eques Investment" />
              {/* <img src={w2} alt="Forum" /> */}
              <img src={w3} alt="Ipopema" />
              <img src={w4} alt="Mount" />
              <img src={w5} alt="Noble Funds" />
              <img src={w6} alt="Opoka" />
              <img src={w7} alt="Rockbridge" />
              <img src={w8} alt="Skarbiec" />
              {/* <img src={w9} alt="Tar Heel Capital" /> */}
              <img src={w10} alt="AgioFunds" />
            </div>
          </Container>
        </div>
      </section>
    );
  }
  /* Ustawienia karuzeli */
  componentDidMount() {
    const options = {
      selector: ".carousel",
      easing: "cubic-bezier(0.55, 0.16, 0.59, 0.98)",
      duration: 600, /* Czas trwania przejścia */
      loop: true, /* Zapętlenie */
      perPage: { /* Ilość elementów wyświtlanych na stronie w zależności od rozdzielczości */
        0: 1,
        320: 2,
        480: 3,
        576: 3,
        768: 4,
        1024: 6,
      },
    };

    this.siema = new Siema(options);

    /* Ustawienie prędkości przesuwania się ikon */
    setInterval(() => this.siema.next(), 2500);
  }
}

/* Generowanie sekcji "Kredyty" */
class Credits extends Component {
  render() {
    return (
      <section className="gold-separator scrollspy" id="credit">
        <Container className="moving-show">
          <Row className="section-name">
            <Col lg={12} md={12} sm={12} xs={12}>
              <h1><Trans>Kredyty</Trans></h1>
            </Col>
          </Row>
          <Row className="animation">
            <Col lg={3} md={3} sm={6} xs={12}>
              <img src={ang} className="ang" alt="Logo ANG" />
            </Col>
            <Col lg={9} md={9} sm={12} xs={12}>
              <p>
                <Trans i18nKey="Credits-description">BusinessWise jest licencjonowanym i&nbsp;nadzorowanym przez Urząd Komisji Nadzoru Finansowego podmiotem rynku bankowego. Jesteśmy Agentem Pośrednika Kredytu Hipotecznego Grupa ANG S.A., która skupia pod swoim dachem oferty największych polskich banków. Pomagamy przeprowadzić skutecznie proces kredytowy. Opiniujemy i&nbsp;przedstawiamy jak najszersze możliwości, tak aby każda decyzja była podjęta rozsądnie. Numer agenta pośrednika kredytu hipotecznego: RHA0008750.</Trans>
              </p>
            </Col>
          </Row>
          <Row className="padding-top-sep show-on-mobile">
            <div className="little-gold-separator"></div>
          </Row>
        </Container>
      </section>
    );
  }
}
class Gold extends Component {
  render() {
    return (
      <section className="gold-separator scrollspy" id="gold">
        <Container className="moving-show">
          <Row className="section-name">
            <Col lg={12} md={12} sm={12} xs={12}>
              <h1><Trans>Złoto</Trans></h1>
            </Col>
          </Row>
          <Row className="animation">
            <Col lg={3} md={3} sm={6} xs={12}>
              <img src={goldimg} className="pmth" alt="Logo PMTH" />
            </Col>
            <Col lg={9} md={9} sm={12} xs={12}>
              <p>
                <Trans i18nKey="gold-description-1">W 2024 roku nawiązaliśmy współpracę z grupą PMTH ("Precious Metals Trading Hub"), będącą jedynym polskim podmiotem prowadzącym hurtową dystrybucję złotych i srebrnych sztab oraz monet inwestycyjnych w Polsce. W ciągu pierwszych ośmiu lat działalności PMTH sprzedała ponad 10 ton złota o wartości ponad 2,5 mld PLN. Wieloletnie doświadczenie i skala działalności naszego partnera daje nam możliwość dostępu do najbardziej rozpoznawalnych i wiarygodnych producentów złota na świecie, zrzeszonych w Londyńskim Stowarzyszeniu Rynku Kruszców („LBMA”), produkujących sztabki oraz monety bulionowe t.j. Valcambi, Argor-Heraeus, Metalor, Perth Mint, South African Mint, Royal Canadian Mint, Munze Osterreich oraz wielu innych. </Trans>
              </p>
              <p>
                <Trans i18nKey="gold-description-2">Nawiązanie współpracy z PMTH otworzyło nam również dostęp do usług w postaci magazynowania czy logistyki fizycznego złota w oparciu o ich współpracę z profesjonalnymi agencjami ochrony mienia i światowymi operatorami logistycznymi takimi jak np. Loomis International.</Trans>
              </p>
            </Col>
          </Row>
          <Row className="padding-top-sep show-on-mobile">
            <div className="little-gold-separator"></div>
          </Row>
        </Container>
      </section>
    );
  }
}

/* Generowanie sekcji "Nasz Zespół" */
const flip_speed = 800; /* Szybkość obracania się elementów */
class Team extends Component {
  render() {
    return (
      <section className="scrollspy" id="team">
        <Container className="moving-show">

          {/* Nagłówek sekcji */}
          <Row className="section-name">
            <Col lg={12} md={12} sm={12} xs={12}>
              <h1><Trans>Zespół</Trans></h1>
            </Col>
          </Row>

          {/* Treść sekcji */}
          <Row className="team-container animation">
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>

              <Flippy ref={(r) => (this.flippyHorizontal_1 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_1.toggle()}><img src={z1} alt="MS" /><div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Marcin Simkiewicz</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-1">Założyciel, <br />Członek Zarządu</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:marcin@businesswise.pl" target="_top">marcin@businesswise.pl</a></div>
                    </div>
                  </div>

                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_1.toggle()}>
                    <div className="team-name"><h2>Marcin Simkiewicz</h2></div>
                    <div className="team-city"><p>Warszawa</p></div>
                    <div className="team-description">
                        <p>Magister ekonomii, absolwent Szkoły Głównej Handlowej w&nbsp;Warszawie na&nbsp;kierunku Finanse i&nbsp;Rachunkowość – specjalność: Rynki Finansowe. </p>
                        <p>Związany z&nbsp;branżą finansową od&nbsp;ponad dekady. Jeden z&nbsp;pomysłodawców i&nbsp;założycieli BusinessWise, pełniący w&nbsp;Spółce funkcję Członka Zarządu. </p>
                        <p>Prywatnie miłośnik podróży oraz sportów wodnych i&nbsp;górskich.</p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>
                  </div>
                </BackSide>
              </Flippy>

            </Col>
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>
              <Flippy ref={(r) => (this.flippyHorizontal_2 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_2.toggle()}><img src={z2} alt="MS" /><div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Bartłomiej Kuwał</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-1">Założyciel, <br />Członek Zarządu</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:bartlomiej@businesswise.pl" target="_top">bartlomiej@businesswise.pl</a></div>
                    </div>
                  </div>
                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_2.toggle()}>
                    <div className="team-name"><h2>Bartłomiej Kuwał</h2></div>
                    <div className="team-city"><p>Warszawa</p></div>
                    <div className="team-description">
                        <p>Ukończył Szkołę Główną Handlową w&nbsp;Warszawie na kierunku Finanse i&nbsp;Rachunkowość – specjalności: Międzynarodowe Rynki Finansowe i&nbsp;Finanse Przedsiębiorstw.</p> 
                        <p>Związany z&nbsp;segmentem Private Banking od&nbsp;2010 roku. Jeden z&nbsp;pomysłodawców i&nbsp;założycieli BusinessWise, pełniący w&nbsp;Spółce funkcję Członka Zarządu. </p>
                        <p>Miłośnik górskich wypraw i&nbsp;narciarstwa. Uczestnik zawodów triathlonowych.</p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>
                  </div>
                </BackSide>
              </Flippy>

            </Col>
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>
              <Flippy ref={(r) => (this.flippyHorizontal_3 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_3.toggle()}><img src={z3} alt="MS" /><div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Paweł Szemetiuk</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-2">Wspólnik</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:pszemetiuk@businesswise.pl" target="_top">pszemetiuk@businesswise.pl</a></div>
                    </div>
                  </div>

                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_3.toggle()}>
                    <div className="team-name"><h2>Paweł Szemetiuk</h2></div>
                    <div className="team-city"><p>Warszawa</p></div>
                    <div className="team-description">
                      <p>Ekspert w&nbsp;zakresie funduszy inwestycyjnych i&nbsp;planowania finansowego.</p> 
                      <p>Praktyk na&nbsp;rynku finansowym od&nbsp;ponad 20-stu lat. Od&nbsp;2004 roku związany z&nbsp;branżą Private Banking i&nbsp;kompleksową obsługą klientów zamożnych oraz firmowych. Obecnie wspólnik BusinessWise. </p>
                      <p>Pasjonat górskich wędrówek i&nbsp;miłośnik skandynawskiej literatury.</p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>
                  </div>
                </BackSide>
              </Flippy>

            </Col>
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>
              <Flippy ref={(r) => (this.flippyHorizontal_4 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_4.toggle()}><img src={z4} alt="MS" /><div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Radosław Nieścior</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-2">Wspólnik</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:rniescior@businesswise.pl" target="_top">rniescior@businesswise.pl</a></div>
                    </div>
                  </div>

                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_4.toggle()}>
                    <div className="team-name"><h2>Radosław Nieścior</h2></div>
                    <div className="team-city"><p>Lublin</p></div>
                    <div className="team-description">
                      <p>Absolwent Politechniki Lubelskiej z&nbsp;wykształceniem ekonomicznym. </p>
                      <p>Od&nbsp;2004 roku związany z&nbsp;sektorem finansowym gdzie zdobywa doświadczenie w&nbsp;zakresie funduszy inwestycyjnych i&nbsp;bankowości prywatnej. Obecnie wspólnik BusinessWise. </p>
                      <p>Prywatnie pasjonat podróży i&nbsp;sportów wodnych. </p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>

                  </div>
                </BackSide>
              </Flippy>

            </Col>
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>
              <Flippy ref={(r) => (this.flippyHorizontal_5 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_5.toggle()}><img src={z5} alt="MS" /> <div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Krzysztof Stawecki</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-2">Wspólnik</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:kstawecki@businesswise.pl" target="_top">kstawecki@businesswise.pl</a></div>
                    </div>
                  </div>

                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_5.toggle()}>
                    <div className="team-name"><h2>Krzysztof Stawecki</h2></div>
                    <div className="team-city"><p>Poznań</p></div>
                    <div className="team-description">
                      <p>Absolwent Uniwersytetu Ekonomicznego w&nbsp;Poznaniu. </p>
                      <p>Związany jest z&nbsp;rynkiem finansowym od&nbsp;2006 roku. Doświadczony we&nbsp;współpracy z&nbsp;klientem z&nbsp;sektora Private Banking w&nbsp;tym budowaniu długoterminowych relacji. Ekspert w&nbsp;rozwijaniu i&nbsp;finansowaniu projektów deweloperskich. Obecnie wspólnik BusinessWise. </p>
                      <p>Pasjonat szybownictwa i&nbsp;miłośnik wędkarstwa sportowego.</p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>
                  </div>
                </BackSide>
              </Flippy>

            </Col>
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>
              <Flippy ref={(r) => (this.flippyHorizontal_6 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_6.toggle()}><img src={z6} alt="MS" /><div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Marcin Dettloff</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-2">Wspólnik</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:mdettloff@businesswise.pl" target="_top">mdettloff@businesswise.pl</a></div>
                    </div>
                  </div>

                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_6.toggle()}>
                    <div className="team-name"><h2>Marcin Dettloff</h2></div>
                    <div className="team-city"><p>Poznań</p></div>
                    <div className="team-description">
                      <p>Z wykształcenia ekonomista. </p>
                      <p>Doświadczenie w&nbsp;finansach zdobywał w&nbsp;dziale usług finansowych jednej z&nbsp;największych, ogólnopolskiej sieci dyskontów.</p>
                      <p>Od blisko dekady związany z&nbsp;sektorem bankowości prywatnej. Obecnie wspólnik BusinessWise.</p> 
                      <p>Prywatnie miłośnik koszykówki, budowania komputerów i&nbsp;gotowania.</p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>
                  </div>
                </BackSide>
              </Flippy>

            </Col>
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>
              <Flippy ref={(r) => (this.flippyHorizontal_8 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_8.toggle()}><img src={z8} alt="Marcin Michalski" /><div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Marcin Michalski</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-2">Wspólnik</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:mmichalski@businesswise.pl" target="_top">mmichalski@businesswise.pl</a></div>
                    </div>
                  </div>

                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_8.toggle()}>
                    <div className="team-name"><h2>Marcin Michalski</h2></div>
                    <div className="team-city"><p>Wrocław</p></div>
                    <div className="team-description">
                      <p>Absolwent Uniwersytetu Ekonomicznego we&nbsp;Wrocławiu na&nbsp;kierunkach Finanse i&nbsp;Rachunkowość oraz Międzynarodowych Stosunków Gospodarczych.</p>
                      <p>Od&nbsp;2012 roku związany z&nbsp;obsługą klientów segmentu Private Banking. W&nbsp;swojej pracy skupia&nbsp;się przede wszystkim na&nbsp;przekazywaniu wiedzy i dążeniu do rozwoju osobistego klientów. Obecnie wspólnik BusinessWise.</p>
                      <p>Prywatnie instruktor tenisa ziemnego.</p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>
                  </div>

                </BackSide>
              </Flippy>

            </Col>
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>
              <Flippy ref={(r) => (this.flippyHorizontal_9 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_9.toggle()}><img src={z9} alt="Tomasz Bzdyk" /><div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Tomasz Bzdyk</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-2">Wspólnik</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:tbzdyk@businesswise.pl" target="_top">tbzdyk@businesswise.pl</a></div>
                    </div>
                  </div>

                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_9.toggle()}>
                    <div className="team-name"><h2>Tomasz Bzdyk</h2></div>
                    <div className="team-city"><p>Wrocław</p></div>
                    <div className="team-description">
                      <p>Absolwent Uniwersytetu Ekonomicznego we&nbsp;Wrocławiu.</p>
                      <p>Z&nbsp;rynkiem finansowym związany od&nbsp;2009 roku. Od&nbsp;ponad dekady współpracuje z&nbsp;Klientami z&nbsp;sektora Private Banking. W&nbsp;pracy najważniejsza jest dla niego jakość, rzetelność oraz dbałość o&nbsp;powierzone aktywa. Obecnie wspólnik BusinessWise.</p>
                      <p>Prywatnie uwielbia biegać i&nbsp;oglądać dobre filmy kryminalne.</p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>
                  </div>

                </BackSide>
              </Flippy>

            </Col>
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>
              <Flippy ref={(r) => (this.flippyHorizontal_10 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_10.toggle()}><img src={z10} alt="Adam Kalinowski" /><div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Adam Kalinowski</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-2">Wspólnik</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:akalinowski@businesswise.pl" target="_top">akalinowski@businesswise.pl</a></div>
                    </div>
                  </div>

                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_10.toggle()}>
                    <div className="team-name"><h2>Adam Kalinowski</h2></div>
                    <div className="team-city"><p>Wrocław</p></div>
                    <div className="team-description">
                      <p>Absolwent Akademii Wychowania Fizycznego we&nbsp;Wrocławiu.</p>
                      <p>Doświadczenie na&nbsp;rynku finansowym zaczął zdobywać w&nbsp;2010 roku, pracując w&nbsp;jednym z&nbsp;polskich banków. Doświadczony we&nbsp;współpracy z&nbsp;klientem z&nbsp;sektora Private Banking. Obecnie wspólnik w&nbsp;BusinessWise.</p>
                      <p>Prywatnie, szczęśliwy mąż i&nbsp;ojciec dwóch chłopców, z&nbsp;którymi większość wolnego czasu spędza na&nbsp;dwóch kółkach.</p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>
                  </div>

                </BackSide>
              </Flippy>

            </Col>
            <Col className="team-element" lg={3} md={6} sm={6} xs={12}>
              <Flippy ref={(r) => (this.flippyHorizontal_7 = r)}
                flipOnClick={false}>
                <FrontSide animationDuration={flip_speed}>
                  <div className="team-element-front-wrap">
                    <div className="team-wrap">
                      <div className="team-img" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_7.toggle()}><img src={z7} alt="MS" /><div className="team-front-flip-toggle"><Trans>O mnie</Trans> {'>'}</div><div onclick="void(0)" className="team-img-plus icon"></div></div>
                      <div className="team-name"><h2>Patrycja Podgórska-Pomes</h2></div>
                      <div className="team-position"><h3><Trans i18nKey="Team-position-3">Koordynator <br />ds. operacyjnych</Trans></h3></div>
                    </div>
                    <div className="team-wrap">
                      <div className="team-separator"></div>
                      <div className="team-email"><a href="mailto:ppomes@businesswise.pl" target="_top">ppomes@businesswise.pl</a></div>
                    </div>
                  </div>

                </FrontSide>
                <BackSide animationDuration={flip_speed}>
                  <div className="team-element-back-wrap" style={{ cursor: "pointer" }} onClick={() => this.flippyHorizontal_7.toggle()}>
                    <div className="team-name"><h2>Patrycja Podgórska-Pomes</h2></div>
                    <div className="team-city"><p>Warszawa</p></div>
                    <div className="team-description">
                      <p>Studentka na kierunku Finanse i Rachunkowość - specjalność: Bankowość i Doradztwo Inwestycyjne.</p>
                      <p>Związana z sektorem bankowym od&nbsp;2012 roku. W&nbsp;BusinessWise pełni funkcję Koordynatora ds.&nbsp;operacyjnych. </p>
                      <p>W&nbsp;wolnym czasie chętnie sięga po&nbsp;książkę, najczęściej z&nbsp;zakresu psychologii i&nbsp;edukacji. Wielbicielka dobrych kryminałów.</p>
                    </div>
                    <div className="team-back-flip-toggle"><span className="icon-close" /></div>
                  </div>

                </BackSide>
              </Flippy>

            </Col>
          </Row>

          {/* Złoty separator wyświetlany tylko na użądzeniach mobilnych */}
          <Row className="padding-top-sep show-on-mobile">
            <div className="little-gold-separator"></div>
          </Row>

        </Container>
      </section>
    );
  }
}

export default withTranslation(["translations"])(Home);
